










































































































































































































import ViewModel from '@/views/contact/Customer.ts';
export default ViewModel;
